import React, { useEffect } from "react";
import {
  useSpring,
  useChain,
  SpringRef,
  Spring,
  Trail,
  useTrail,
  useTransition,
} from "react-spring";
import useMightyMouse from "react-hook-mighty-mouse";
import { Heading, Box } from "@chakra-ui/react";

export function TwoEyes({ containerRef, ...rest }) {
  const {
    position: { client, page, screen },
  } = useMightyMouse(true, 'containa', {x: 20, y:20});

  const leftEyeRef = React.useRef();
  const rightEyeRef = React.useRef();
  const leftPupilRef = React.useRef();
  const rightPupilRef = React.useRef();
  const leftGlareRef = React.useRef();
  const rightGlareRef = React.useRef();
  let eyes;

  React.useEffect(() => {
    if (leftEyeRef.current && rightEyeRef.current) {
      //console.log(leftEyeRef.current.getBoundingClientRect())
      // const bound = leftEyeRef.current.getBoundingClientRect()
      const leftEye = leftEyeRef.current;
      const rightEye = rightEyeRef.current;
      const rightPupil = rightPupilRef.current;
      const rightGlare = rightGlareRef.current;
      const leftPupil = leftPupilRef.current;
      const leftGlare = leftGlareRef.current;
      console.log(leftEye);
      const bound = leftEye.getBoundingClientRect();
      const boundRight = rightEye.getBoundingClientRect();
      console.log(bound);
      const baseVal = leftEye.cx.baseVal.value;
      console.log(baseVal);
      const rightEyeR = rightEye.r.baseVal.value;
      const rightPupilR = rightPupil.rx.baseVal.value;
      const rightGlareR = rightGlare.rx.baseVal.value;
      const leftEyeR = leftEye.r.baseVal.value;
      const leftPupilR = leftPupil.rx.baseVal.value;
      const leftGlareR = leftGlare.rx.baseVal.value;
      let cx = bound.left + leftEyeR;
      let cy = bound.bottom - leftEyeR;

      let cxRight = boundRight.left + rightEyeR;
      let cyRight = boundRight.bottom - rightEyeR;
      let xRight = client.x - cxRight;
      let yRight = client.x - cyRight;
      let x = client.x - cx;
      let y = client.y - cy;
      let rightD = Math.sqrt(xRight * xRight + yRight * yRight);
      let rightTheta = Math.atan2(yRight, xRight);
      let rightAngle = (rightTheta * 180) / Math.PI + 360;
      let d = Math.sqrt(x * x + y * y);
      let theta = Math.atan2(y, x);
      let angle = (theta * 180) / Math.PI + 360;

      let max = 200.0;
      if (d > max) d = max;

      if (rightD > max) rightD = max;

      let t = (d / max) * (leftEyeR - leftPupilR);
      let t2 = (d / max) * (leftEyeR - leftGlareR);

      let tRight = (rightD / max) * (rightEyeR - rightPupilR);
      let t2Right = (rightD / max) * (rightEyeR - rightGlareR);

      leftPupil.style.transform = `translate(${t + "px"}) rotate(${
        angle + "deg"
      })`;
      leftPupil.style.transformOrigin = `${
        leftEye.cx.baseVal.value - t + "px"
      } ${leftEye.cy.baseVal.value + "px"}`;

      rightPupil.style.transform = `translate(${t + "px"}) rotate(${
        angle + "deg"
      })`;
      rightPupil.style.transformOrigin = `${
        rightEye.cx.baseVal.value - t + "px"
      } ${rightEye.cy.baseVal.value + "px"}`;

      leftGlare.style.transform = `translate(${t2 + "px"}) rotate(${
        angle + "deg"
      })`;
      leftGlare.style.transformOrigin = `${
        leftEye.cx.baseVal.value - t2 + "px"
      } ${leftEye.cy.baseVal.value + "px"}`;

      rightGlare.style.transform = `translate(${t2 + "px"}) rotate(${
        angle + "deg"
      })`;
      rightGlare.style.transformOrigin = `${
        rightEye.cx.baseVal.value - t2 + "px"
      } ${rightEye.cy.baseVal.value + "px"}`;
    }
  });

  return (
    <>
      {/* <Box pt={10} color="white" fontsize="5xl" w="100%" h="100px">

      </Box> */}
      <svg
        height='100px'
        width='150px'
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 93.558 28.251"
      >
        <g fill="#213b4f">
          <path
            d="M26.474 38.624a26.273 26.273 0 01-21.551-11.2.868.868 0 01.002-.997 26.385 26.385 0 0143.148-.004.867.867 0 01.002 1.004 26.466 26.466 0 01-21.6 11.197zM6.704 26.928a24.542 24.542 0 0019.77 9.96 24.73 24.73 0 0019.818-9.96 24.646 24.646 0 00-39.587 0z"
            transform="translate(-4 -10.582)"
          ></path>
          <path
            d="M26.474 16.967a.869.869 0 01-.868-.869V11.45a.868.868 0 111.737 0v4.648a.869.869 0 01-.869.869zM8.24 24.68a.866.866 0 01-.6-.24l-3.37-3.218a.868.868 0 111.197-1.257l3.372 3.218a.868.868 0 01-.6 1.496zM16.566 18.959a.87.87 0 01-.805-.543l-1.737-4.29a.868.868 0 111.61-.651l1.737 4.29a.868.868 0 01-.805 1.194zM44.76 24.68a.868.868 0 01-.599-1.497l3.372-3.218a.868.868 0 011.198 1.257l-3.372 3.217a.866.866 0 01-.599.24zM36.383 18.959a.868.868 0 01-.801-1.203l1.788-4.29a.868.868 0 111.603.669l-1.788 4.29a.868.868 0 01-.802.534z"
            transform="translate(-4 -10.582)"
          ></path>
        </g>
        <g className="eye">
          <circle
            ref={leftEyeRef}
            id="lefteye"
            className="eyeball"
            cx="22.273"
            cy="16.546"
            r="9.8"
            //rx='14'
            //ry={'5'}
          ></circle>
          {/* <circle
        className='eyeball'
        cx="22.273"
        cy="16.546"
        r="5.84"
        fill="none"
        stroke="#213b4f"
        strokeMiterlimit="10"
      ></circle> */}
          <ellipse
            ref={leftPupilRef}
            className="pupil"
            cx="22.273"
            cy="16.546"
            rx="5.84"
            ry="5.84"
            fill="white"
          ></ellipse>
          <ellipse
            ref={leftGlareRef}
            className="glare"
            cx={"22.273"}
            cy="16.546"
            rx="2.3"
            ry="2.8"
          ></ellipse>
          {/* <circle  className='pupil glare' cx="22.273" cy="16.546" r="1.947" fill="#213b4f"></circle> */}
        </g>
        <g fill="#213b4f">
          <path
            d="M75.033 38.833a26.273 26.273 0 01-21.552-11.2.868.868 0 01.002-.997 26.385 26.385 0 0143.148-.004.867.867 0 01.002 1.004 26.466 26.466 0 01-21.6 11.197zm-19.77-11.696a24.542 24.542 0 0019.77 9.96 24.73 24.73 0 0019.817-9.96 24.646 24.646 0 00-39.587 0z"
            transform="translate(-4 -10.582)"
          ></path>
          <path
            d="M75.033 17.176a.869.869 0 01-.869-.869V11.66a.868.868 0 011.737 0v4.648a.869.869 0 01-.868.869zM56.798 24.888a.866.866 0 01-.6-.24l-3.37-3.217a.868.868 0 011.198-1.257l3.371 3.218a.868.868 0 01-.599 1.496zM65.124 19.168a.87.87 0 01-.805-.543l-1.737-4.29a.868.868 0 011.61-.651l1.737 4.29a.868.868 0 01-.805 1.194zM93.318 24.888a.868.868 0 01-.599-1.496l3.372-3.218a.868.868 0 111.198 1.257l-3.372 3.217a.866.866 0 01-.599.24zM84.941 19.168a.868.868 0 01-.801-1.203l1.788-4.29a.868.868 0 011.603.669l-1.788 4.29a.868.868 0 01-.802.534z"
            transform="translate(-4 -10.582)"
          ></path>
        </g>
        <g className="eye">
          {/* <circle
        className='eyeball'
        cx="70.831"
        cy="16.755"
        r="5.84"
        fill="none"
        stroke="#213b4f"
        strokeMiterlimit="10"
      ></circle> */}
          {/* <circle className='pupil glare' cx="70.831" cy="16.755" r="1.947" fill="#213b4f"></circle> */}
          <circle
            ref={rightEyeRef}
            id="righteye"
            className="eyeball"
            cx="70.831"
            cy="16.755"
            r="9.8"
          ></circle>
          <ellipse
            ref={rightPupilRef}
            className="pupil"
            cx="70.831"
            cy="16.755"
            rx="5.84"
            ry="5.84"
            fill="white"
          ></ellipse>
          <ellipse
            ref={rightGlareRef}
            className="glare"
            cx={"70.831"}
            cy="16.755"
            rx="2.25"
            ry="2.8"
          ></ellipse>
        </g>
      </svg>
    </>
  );
}

const Eyes = () => {
  return (
    <>
      <TwoEyes />
    </>
  );
};

export default Eyes;

function EyeClosed() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576.398 359.168">
      <g data-name="Layer 2">
        <g fill="#213b4f" data-name="Layer 1">
          <path d="M287.872 299.619c-110.184 0-213.382-53.622-276.053-143.442a11.1 11.1 0 01.022-12.758 337.97 337.97 0 01552.716 0 11.1 11.1 0 01.023 12.758c-62.672 89.82-166.117 143.442-276.708 143.442zM34.64 149.843c58.885 80.016 152.954 127.552 253.232 127.552 100.685 0 194.99-47.536 253.886-127.552a315.7 315.7 0 00-507.118 0z"></path>
          <path d="M287.872 359.168a11.108 11.108 0 01-11.123-11.112v-59.55a11.123 11.123 0 0122.247 0v59.55a11.108 11.108 0 01-11.124 11.112zM11.12 242.053a11.118 11.118 0 01-7.618-19.227l43.186-40.57a11.13 11.13 0 0115.236 16.228l-43.185 40.57a11.142 11.142 0 01-7.619 2.999zM138.705 329.1a10.882 10.882 0 01-4.17-.834 11.113 11.113 0 01-6.141-14.47l22.246-54.974a11.123 11.123 0 0120.624 8.34l-22.247 54.974a11.143 11.143 0 01-10.312 6.964zM565.278 242.053a11.142 11.142 0 01-7.618-2.998l-43.186-40.571a11.13 11.13 0 0115.237-16.229l43.186 40.571a11.118 11.118 0 01-7.619 19.227zM437.693 329.1a11.15 11.15 0 01-10.267-6.852l-22.9-54.974a11.124 11.124 0 1120.534-8.565l22.9 54.974a11.134 11.134 0 01-5.985 14.561 11.516 11.516 0 01-4.282.856z"></path>
        </g>
      </g>
    </svg>
  );
}

function EyeOpen() {
  // const eyeAngle = React.useState()

  // const {
  //     selectedElement: {
  //         position: { angle: pupil1},

  //     },
  // } = useMightyMouse(true, 'pupil', {x: 20, y:20})

  // const rotatePupil = `rotate(${-pupil1}, 45, 34)`

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 1000">
      <g class="eye">
        <circle
          class="eyeball"
          fill-rule="evenodd"
          clip-rule="evenodd"
          cx="131"
          cy="92"
          r="42"
          fill="white"
        />
        <ellipse
          class="pupil"
          fill-rule="evenodd"
          clip-rule="evenodd"
          cx="131"
          cy="92"
          rx="14.829"
          ry="16.062"
        />
        <ellipse
          class="glare"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          cx="131"
          cy="92"
          rx="3.496"
          ry="4.082"
        />
      </g>
      <g class="eye">
        <circle
          class="eyeball"
          fill-rule="evenodd"
          clip-rule="evenodd"
          cx="258"
          cy="87"
          r="42"
          fill="white"
        />
        <ellipse
          class="pupil"
          fill-rule="evenodd"
          clip-rule="evenodd"
          cx="258"
          cy="87"
          rx="14.582"
          ry="16.062"
        />
        <ellipse
          class="glare"
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill="#FFFFFF"
          cx="258"
          cy="87"
          rx="3.438"
          ry="4.082"
        />
      </g>
      {/* <g data-name="Layer 2">
          <g data-name="Layer 1">
            <g fill="#213b4f">
              <path d="M46.183 57.624A53.99 53.99 0 011.896 34.608 1.784 1.784 0 011.9 32.56a54.22 54.22 0 0188.665-.007 1.782 1.782 0 01.004 2.063 54.385 54.385 0 01-44.386 23.008zM5.558 33.591a50.431 50.431 0 0040.625 20.464 50.82 50.82 0 0040.723-20.464 50.645 50.645 0 00-81.348 0z"></path>
              <path d="M46.183 13.12a1.785 1.785 0 01-1.785-1.784V1.784a1.784 1.784 0 013.57 0v9.552a1.785 1.785 0 01-1.785 1.785zM8.712 28.97a1.78 1.78 0 01-1.23-.494L.552 21.864a1.784 1.784 0 112.463-2.582l6.928 6.612a1.784 1.784 0 01-1.232 3.075zM25.821 17.214a1.786 1.786 0 01-1.654-1.116l-3.569-8.815a1.784 1.784 0 013.309-1.338l3.569 8.815a1.785 1.785 0 01-1.655 2.454zM83.758 28.97a1.784 1.784 0 01-1.231-3.076l6.928-6.612a1.784 1.784 0 012.463 2.582l-6.929 6.612a1.78 1.78 0 01-1.23.493zM66.544 17.214a1.784 1.784 0 01-1.647-2.472l3.674-8.815A1.785 1.785 0 0171.865 7.3l-3.674 8.815a1.784 1.784 0 01-1.647 1.098z"></path>
            </g>
            <svg>
            <circle
         
           
              cx="45.769"
              cy="34"
              r="12"
              fill="none"
              stroke="#213b4f"
              strokeMiterlimit="10"
              strokeWidth="2"
            ></circle>
            <circle    transform={rotatePupil}  id='pupil'  cx="45.769" cy="34" r="4" fill="#213b4f"></circle>
            </svg>
          </g>
        </g> */}
    </svg>
  );
}
