import './src/components/carousel/carousel.scss'

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
  }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook
    const scrollToTopRoutes = [`/*`]
    // if the new route is part of the list above, scroll to top (0, 0)
   
      window.scrollTo(0, 0)
    
  
    
  }