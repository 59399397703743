import React from 'react'
import Footer from './footer/Footer'
import MyHead from './Header/MyHead'
import ProjectArrows from './Header/ProjectArrows'
import './layout.css'

export default function Layout({children}) {
    return (
        <>
       
    <MyHead />
    <div style={{margin:'0', padding: 0}}>{children}</div>
  
    <Footer />

    </>)
}