import projectLinks from "./contants";
import React, { useEffect, useState } from "react";
import { Flex, HStack, SimpleGrid, Spacer, Text } from "@chakra-ui/react";
import { FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { navigate, Link } from "gatsby";
import Loadable from '@loadable/component'


function ProjectArrows({ currentPath }) {
  //const url = typeof window !== 'undefined' ? window.location.href : '';
  const projects = projectLinks;
  const path = currentPath;
  const [locked, setLocked] = useState(true)
  useEffect(() => setLocked(false), [])

  const [pathState, setPathState ] = useState('/')
  useEffect(() => {
    setPathState(currentPath)
  }, [currentPath])
 
  switch (pathState) {
    case "/project/wilson/" | "/project/wilson":
      return (
        
        <Flex py={5} align="center" justify="center" w="100%">
          <HStack align="center">
            <Flex> Go to project: VPA</Flex>
            <FaArrowCircleRight onClick={() => navigate("/project/vpa")} />
          </HStack>
        </Flex>
      );
    case ("/project/vpa/" || "/project/vpa"):
      return (
        <Flex align="center" justify="center" py={5} w="100%">
          <HStack align="center">
            <Link to="/project/wilson">
              {" "}
              <FaArrowCircleLeft />
            </Link>{" "}
            <Flex>
              Go to project: Wilson Associates | Go to project: Hellofresh
              Packaging
            </Flex>
            <Link to="/project/hellofresh-packaging">
              <FaArrowCircleRight />
            </Link>
          </HStack>
        </Flex>
      );
    case ("/project/hellofresh-packaging" || "/project/hellofresh-packaging/"):
      return (
        <Flex align="center" justify="center" py={5} w="100%">
          <HStack align="center">
            <Link to="/project/vpa">
              {" "}
              <FaArrowCircleLeft />
            </Link>{" "}
            <Flex>
              Go to project: VPA | Go to project: Hellofresh Marketing{" "}
            </Flex>
            <Link to="/project/hellofresh-marketing">
              <FaArrowCircleRight />
            </Link>
          </HStack>
        </Flex>
      );
    case ("/project/hellofresh-marketing/" || "/project/hellofresh-marketing"):
      return (
        <Flex align="center" justify="center" py={5} w="100%">
          <HStack align="center">
            <Link to="/project/hellofresh-packaging">
              {" "}
              <FaArrowCircleLeft />
            </Link>{" "}
            <Flex>
              Go to project: Hellofresh Packaging | Go to project: Hellofresh
              NFL Hall of Fame{" "}
            </Flex>
            <Link to="/project/nfl">
              <FaArrowCircleRight />
            </Link>
          </HStack>
        </Flex>
      );
    case ("/project/nfl/" || "/project/nfl"):
      return (
      
        <Flex align="center" justify="center" py={5} w="100%">
          <Link className="navvylink" to="/project/hellofresh-marketing">
            {" "}
            <FaArrowCircleLeft
              style={{ alignSelf: "center", marginRight: "3px" }}
            />
            <Flex>Go to project: Hellofresh Marketing</Flex>
          </Link>{" "}
          <Flex px={3}> | </Flex>
          <Link className="navvylink" to="/project/copper-club">
            {" "}
            <Flex>Go to project: The Copper Club </Flex>
            <FaArrowCircleRight
              style={{ alignSelf: "center", marginLeft: "3px" }}
            />
          </Link>
        </Flex>
   
      );
    case ("/project/copper-club/" || "/project/copper-club"):
      return (
 
        <Flex align="center" justify="center" py={5} w="100%">
          <Link className="navvylink" to="/project/nfl">
            {" "}
            <FaArrowCircleLeft
              style={{ alignSelf: "center", marginRight: "3px" }}
            />
            <Flex>Go to project: NFL Hall of Fame</Flex>
          </Link>{" "}
          <Flex px={3}> | </Flex>
          <Link className="navvylink" to="/explore">
            {" "}
            <Flex>Go Exploring</Flex>
            <FaArrowCircleRight
              style={{ alignSelf: "center", marginLeft: "3px" }}
            />
          </Link>
        </Flex>
  
      );

    case ("/explore/" || "/explore"):
      return (
     
        <Flex align="center" justify="center" py={5} w="100%">
          <Link className="navvylink" to="/project/copper-club">
            {" "}
            <FaArrowCircleLeft
              style={{ alignSelf: "center", marginRight: "3px" }}
            />
            <Flex>Go to project: The Copper Club</Flex>
          </Link>{" "}
          <Flex px={3}> | </Flex>
          <Link className="navvylink" to="/project/le-lapin">
            {" "}
            <Flex>Go to project: Le Lapin</Flex>
            <FaArrowCircleRight
              style={{ alignSelf: "center", marginLeft: "3px" }}
            />
          </Link>
        </Flex>
   
      );
    case ("/project/le-lapin/" || "/project/le-lapin"):
      return (
      
        <Flex align="center" justify="center" py={5} w="100%">
          <Link className="navvylink" to="/explore">
            {" "}
            <FaArrowCircleLeft
              style={{ alignSelf: "center", marginRight: "3px" }}
            />
            <Flex>Go Exploring</Flex>
          </Link>{" "}
          <Flex px={3}> | </Flex>
          <Link className="navvylink" to="/project/parkhouse">
            {" "}
            <Flex>Go to project: Parkhouse </Flex>
            <FaArrowCircleRight
              style={{ alignSelf: "center", marginLeft: "3px" }}
            />
          </Link>
        </Flex>
    
      );
    case ("/project/parkhouse/" || "/project/parkhouse"):
      return (
        <Flex align="center" justify="center" py={5} w="100%">
          <Link className="navvylink" to="/project/le-lapin">
            {" "}
            <FaArrowCircleLeft
              style={{ alignSelf: "center", marginRight: "3px" }}
            />
            <Flex>Go to project: Le Lapin</Flex>
          </Link>{" "}
          <Flex px={3}> | </Flex>
          <Link className="navvylink" to="/project/hellofresh-marketing">
            {" "}
            <Flex>Go see More Goodies </Flex>
            <FaArrowCircleRight
              style={{ alignSelf: "center", marginLeft: "3px" }}
            />
          </Link>
        </Flex>
      );
    case ("/project/more-goodies/" || "/project/more-goodies"):
      return (
        <Flex align="center" justify="center" py={5} w="100%">
          <Link className="navvylink" to="/project/parkhouse">
            {" "}
            <FaArrowCircleLeft
              style={{ alignSelf: "center", marginRight: "3px" }}
            />
            <Flex>Go to project: Parkhouse</Flex>
          </Link>{" "}
          <Flex px={3}> | </Flex>
          <Link className="navvylink" to="/project/wilson">
            {" "}
            <Flex>Go to project: Wilson </Flex>
            <FaArrowCircleRight
              style={{ alignSelf: "center", marginLeft: "3px" }}
            />
          </Link>
        </Flex>
      );
   
  }

}

export default ProjectArrows

export const NoSSR = ({children}) => {
const [locked, setLocked] = useState(true)
useEffect(() => setLocked(false),[]) //unlock on client side
return locked ? null : children
}

export const ProjectLinkers = ({prevUrl, nextUrl, prevTitle, nextTitle}) => {
  return(
    <Flex px={3} align="center" justify="center" py={5} w="100%">
      <Flex w='100%' px={5}>
        <Flex justifySelf='start'>
    <Link className="navvylink" to={prevUrl}>
    
      <FaArrowCircleLeft
        style={{ alignSelf: "center", marginRight: "3px", fontSize:"25px" }}
      />
      <Flex fontSize='xs'>Go to: {prevTitle}</Flex>
    </Link>{" "}
    </Flex>
  <Spacer />
    <Flex justifySelf='end'>
    <Link className="navvylink" to={nextUrl}>
    
      <Flex fontSize='xs'>Go to: {nextTitle} </Flex>
      <FaArrowCircleRight
        style={{ alignSelf: "center", marginLeft: "3px", fontSize: "25px" }}
      />
    </Link>
    </Flex>
    </Flex>
  </Flex>
  )
}

