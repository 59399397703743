import React from "react";
import { Flex, Heading, Container, Text, Box } from "@chakra-ui/react";
import { Link } from "gatsby";

const Footer = () => {
  return (
    <Flex
      py={10}
      px={[0, null, null, null, 10]}
      justify="space-evenly"
      align={['center']}
      flexDir={['column',null,null,null,'row']}
      w='100%'
      pos="relative"
      bg='blackAlpha.900'>
      <Heading
      
        as='h4'
        fontFamily='acumin-pro-semi-condensed'
        fontSize={['sm',null,null,null,'lg']}
        fontWeight='500'
        color='lightgrey'>
        &copy; 2021 <strong>Hames Design</strong> | All rights reserved.
      </Heading>

     <br/>
      <Heading
     
        fontFamily='acumin-pro-semi-condensed'
        fontWeight='500'
        fontSize={['12px',null,null,null,'lg']}
        textAlign='right'
        color='lightgrey'>
        Web Development by{" "}
        <strong>
          <a href='https://sublimesolutions.tech'>Sublime Solutions</a>
        </strong>
      </Heading>
    </Flex>
  );
};

export default Footer;
