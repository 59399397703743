import React from "react";
import {
  Box,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  Center,
  DrawerHeader,
  DrawerOverlay,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { FaBars, FaRegEnvelope, FaTimes } from "react-icons/fa";
import { Link } from "gatsby";
import styled from "styled-components";
import "./MyHead.css";
import Eyes, { TwoEyes } from "./Eyes";
const MyHead = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const btnRef = React.useRef();
  return (
    <Box
      className="container-fluid"
      position="fixed"
      top={0}
      left={0}
      zIndex="dropdown"
      overflow="hidden"
      maxWidth="100%"
      bg="rgba(253, 253, 248, 0.51)"
    >
      <Flex
        pt={2}
        pb={2}
        pl={[0, null, null, null, "3%"]}
        pr={[0, null, null, null, "3%"]}
        alignItems="baseline"
      >
        <Heading
          textDecor="none"
          textTransform="uppercase"
          mr="auto"
          pl={[2, 3, 5, 7, 15]}
          letterSpacing="wide"
          fontFamily="Acumin-Pro"
          fontWeight="extrabold"
          fontSize={["25", "30"]}
        >
          {" "}
          {/**mr="auto" was previous layout, with both elements pushed to edge */}
          <HomeLink className="headerlogo" to="/">
            Holland Hames
          </HomeLink>
        </Heading>

        <a href="/#contact">
          <IconButton
            pr={[2, 3, 5, 7, 15]}
            color="black"
            variant="link"
            fontSize={["25", "30"]}
            icon={<FaRegEnvelope></FaRegEnvelope>}
          />
        </a>

        <IconButton
          ref={btnRef}
          onClick={isOpen ? onClose : onOpen}
          color="black"
          variant="link"
          fontSize={["25", "30"]}
          icon={isOpen ? <FaTimes /> : <FaBars />}
        />
        <Drawer
          size="full"
          isOpen={isOpen}
          onClose={onClose}
          finalFocusRef={btnRef}
          blockScrollOnMount={false}
          zIndex="tooltip"
        >
          <DrawerOverlay />
          <DrawerContent bg="black">
            <DrawerBody>
              <Flex
              id='containa'
                textTransform="uppercase"
                flexDir="column"
                w="100%"
                h="100%"
                justify="center"
                align="center"
              >
                <Flex justify='center' w='100%'>
              
                 <TwoEyes />
               
               </Flex>
                <LinkWithActive
                  activeClassName='active'
                  // className='inactive'
               
                  to="/"
                >
                  <Heading
                  
                    fontSize={["2xl", "3xl"]}
                    py={3}
                    onClick={onClose} 
                  >
                    Home
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  
                  activeClassName="active"
           
                  to="/project/wilson"
                >
                  <Heading
                   onClick={onClose} 
                   
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    Wilson Associates
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  onClick={onClose}
                  activeClassName="active"
                 
                  to="/project/vpa"
                >
                  <Heading
                   onClick={onClose} 
                  
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    VPA{" "}
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  onClick={onClose}
                  activeClassName="active"
         
                  to="/project/hellofresh-packaging"
                >
                  <Heading
                   onClick={onClose} 
                 
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    Hellofresh Packaging{" "}
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
  
                  to="/project/hellofresh-marketing"
                >
                  <Heading
                    onClick={onClose}
                
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    Hellofresh Marketing
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
          
                  to="/project/nfl"
                >
                  <Heading
                   onClick={onClose} 
              
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    NFL Hall of Fame
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
      
                  to="/project/copper-club"
                >
                  <Heading
                   onClick={onClose} 
              
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    The Copper Club
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
     
                  to="/explore"
                >
                  <Heading
                   onClick={onClose} 
              
                    fontSize={["2xl", "3xl"]}
                    py={3}
                  >
                    Explore
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
          
                  to="/project/le-lapin"
                >
                  <Heading
            
                    fontSize={["2xl", "3xl"]}
                    py={3}
                    onClick={onClose} 
                  >
                    Le Lapin
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
               
                  to="/project/parkhouse"
                >
                  <Heading
            
                    fontSize={["2xl", "3xl"]}
                    py={3}
                    onClick={onClose} 
                  >
                    Parkhouse
                  </Heading>
                </LinkWithActive>
                <LinkWithActive
                  activeClassName="active"
      
                  to="/project/more-goodies"
                >
                  <Heading
              
                    fontSize={["2xl", "3xl"]}
                    py={3}
                    onClick={onClose} 
                  >
                    More Goodies
                  </Heading>
                </LinkWithActive>
              </Flex>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </Box>
  );
};

export default MyHead;

export const LinkWithActive = styled(Link)`
  color: #adb5bd;
  text-decoration: none;
  &:focus {
    color: #dee2e6;
  }
  &:hover {
    color: #dee2e6;
  }
  .active {
    color: #f8f9fa;
  }
`;

export const HomeLink = styled(Link)`
  color: rgb(0, 0, 0, 0.8);
  text-decoration: none;
  transition: 0.5s ease-in-out;
  &:hover {
    color: rgb(0, 0, 0, 0.7);
    transition: 0.5s ease-in-out;
  }

  &:focus {
    color: rgb(0, 0, 0, 0.8);
    transition: 0.5s ease-in-out;
  }
  .active {
    color: rgb(0, 0, 0, 0.8);
    transition: 0.5s ease-in-out;
  }
`;
